import React from "react";
import _ from "lodash";
import classnames from "classnames";
import ReactPixel from "react-facebook-pixel";
import scroll from "scroll";
const page = require("scroll-doc")();
import { setParams, getParams, navigateTo } from "../../utils/location";
import { spreedlyTokenize, recache } from "../../api/spreedlyAPI";
import { PAYMENT_ID } from "../../api/constants";
import {
  NOW,
  PAYMENT,
  SERVING_OPTION,
  GIFT_CARD_FIELDS,
} from "../../utils/constants";
import PaymentAPIScriptLoader, {
  shouldLoadScript,
} from "../PaymentAPIScriptLoader";
import { fireConversionEvent, firePurchaseEvent } from "../../utils/gtm";
import ReactGA from "react-ga";
import SelectedOptionBox from "../SelectedOptionBox";
import AppContainer from "../AppContainer";
import List from "../List";
import Card from "../Card";
import Loader from "../Loader";
import OrderItem from "../OrderItem";
import InlineSelect from "../Inputs/InlineSelect";
import { TextInput, InputGroup } from "../Inputs";
import LocationPinIcon from "../icons/LocationPin.svg";
import ChevronDownIcon from "../icons/ChevronDown.svg";
import Button from "../Button";
import TotalPrice from "../TotalPrice";
import ErrorMessage from "../ErrorMessage";
import PaymentMethodInput from "../PaymentMethodInput";
import CheckoutView from "../checkout-view";
import moment from "../../utils/moment-timezone-with-data-2012-2022";
import getPickupTimesTranslatedInSelectForm from "../../utils/getPickupTimesTranslatedInSelectForm";
import WazeButton from "../WazeButton";
import ConfirmServingOption from "./ConfirmServingOption";
import { LOGIN_TYPES } from "../LoginView";
import { getAppMedia, mediaTypes } from "../../utils/media";
import PlateIcon from "../icons/Plate.svg";
import CreditCardIcon from "../icons/CreditCard.svg";
import CashIcon from "../icons/Cash.svg";
import BagIcon from "../icons/Bag.svg";
import ContactDetails from "../ContactDetails";
import TableIcon from "../icons/Table.svg";
import LockIcon from "../icons/LockIcon.svg";
import GiftCard from "../icons/GiftCard.svg";
import ExternalLink from "../ExternalLink";
import Image from "../image";
import LABELS from "../labels";
import styles from "./index.module.scss";

const APARTMENT = "APARTMENT";
const FLOOR = "FLOOR";
const ENTRANCE = "ENTRANCE";
const DELIVERY_COMMENT = "DELIVERY_COMMENT";

const MANDATORY_FIELDS = [PAYMENT.EMAIL, PAYMENT.PHONE_NUMBER, PAYMENT.NAME];
const FIELDS = _.concat(MANDATORY_FIELDS, [
  APARTMENT,
  ENTRANCE,
  FLOOR,
  DELIVERY_COMMENT,
]);

const CONTACT_DETAILS = "CONTACT_DETAILS";
const PAYMENT_DETAILS = "PAYMENT_DETAILS";
const FIXED_REMARKS = "FIXED_REMARKS";

const CREDIT_CARD = "CC";
const CASH = "CASH";

const errorkey = (key) => `${key}_ERROR`;

const InitialFormFieldsState = FIELDS.reduce(
  (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
  {},
);

const DEFAULT_TIP_OPTIONS = [10, 15, 20, 25];

const CUSTOM_TIP_OPTION_VALUE = "custom";
const SET_TIP_LABEL = "Set Tip";

export default class PaymentView extends React.Component {
  state = {
    ...InitialFormFieldsState,
    shouldLoadPayment: false,
    showOrderSummary: false,
    layoutReady: false,
    paymentTypeChosen: this.props.hidePayment
      ? null
      : this.props.allowCreditCardPayment
      ? CREDIT_CARD
      : this.props.allowCashPayment && !this.props.hideCash
      ? CASH
      : null,
    openCalendar: false,
    errorComponent: null,
    animateErrorElement: false,
    showConfirmServingOption: false,
    balanceChange: 0,
  };
  formRefs = {};
  fieldsRefs = {};

  componentDidMount() {
    const {
      user,
      order,
      paymentTypeDetails,
      pageContext: {
        business: { trackings },
        businessAppConfiguration: { enableValutecGiftCards },
      },
    } = this.props;

    this.fireGTMCheckoutPageEventsIfNeeded();
    this.fireFBPixelCheckoutEventsIfNeeded();

    this.layoutReadyTimer = setTimeout(() => {
      this.setState({ layoutReady: true });
    }, 300);

    if (!order.orderItems.length) {
    } else {
      if (!this.props.hidePayment) {
        this.setState({
          shouldLoadPayment: shouldLoadScript(paymentTypeDetails),
        });

        const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");

        if (
          user.loggedIn &&
          !user.removingPaymentMethods.sending &&
          paymentTypeIdentifier
        ) {
          this.props.loadPaymentMethods(paymentTypeIdentifier);
        }
      }
    }
    if (order.shouldCheckOrderStatus) {
      this.props.checkOrderStatus();
    }
    if (enableValutecGiftCards) {
      this.props.getExternalGiftCards(_.get(order, "branchId"));
    }
  }

  checkOrderStatus = () => {
    this.props.checkOrderStatus();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      paymentTypeDetails,
      servingOptionDetails,
      user,
      pageContext: {
        businessAppConfiguration: { enableValutecGiftCards },
      },
    } = this.props;
    const { servingOption } = servingOptionDetails;
    const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
    if (
      this.state.paymentTypeChosen === CASH &&
      prevState.paymentTypeChosen !== CASH
    ) {
      if (servingOption.needsAddress) {
        this.props.setDeliveryTipPercentage(0);
      } else {
        this.props.setServingOptionTipPercentage(0);
      }
    }

    if (prevProps.hidePayment && !this.props.hidePayment) {
      this.setState(
        {
          paymentTypeChosen: this.props.hidePayment
            ? null
            : this.props.allowCreditCardPayment
            ? CREDIT_CARD
            : this.props.allowCashPayment
            ? CASH
            : null,
        },
        () =>
          _.get(this.props.user, "loggedIn") &&
          !_.get(this.props.user, "paymentMethods.data") &&
          this.props.allowCreditCardPayment &&
          this.props.loadPaymentMethods(paymentTypeIdentifier),
      );
    } else if (!prevProps.user.loggedIn && this.props.user.loggedIn) {
      this.props.allowCreditCardPayment &&
        this.props.loadPaymentMethods(paymentTypeIdentifier);
    }

    if (prevProps.order.placingOrder && this.props.order.orderPlaced) {
      this.fireGTMPaymentConfirmationEventsIfNeeded();
      this.fireFBPixelPurchaseEventsIfNeeded();
      this.fireGAPaymentConfirmationEventsIfNeeded();
      this.props.loadLoyaltyProfile(true);
    }

    if (
      _.get(user, "loyaltyProfile.data.rewardPointBalance.balance") &&
      _.get(user, "loyaltyProfile.data.rewardPointBalance.balance") !==
        _.get(prevProps, "user.loyaltyProfile.data.rewardPointBalance.balance")
    ) {
      this.setState({
        balanceChange:
          _.get(user, "loyaltyProfile.data.rewardPointBalance.balance") -
          _.get(
            prevProps,
            "user.loyaltyProfile.data.rewardPointBalance.balance",
          ),
      });
    }
    if (
      !prevProps.order.shouldCheckOrderStatus &&
      this.props.order.shouldCheckOrderStatus
    ) {
      this.props.checkOrderStatus();
    }
    if (
      enableValutecGiftCards &&
      _.get(user, "addExternalGiftCardState") &&
      _.get(user, "addExternalGiftCardState.sent")
    ) {
      this.props.resetAddExternalGiftCardToAccount();
      this.props.getExternalGiftCards(_.get(order, "branchId"));
    }

    if (this.props.order.orderPlaced !== prevProps.order.orderPlaced) {
      this.props.order.orderPlaced && this.props.resetHistoryCache();
    }
    if (!_.isEqual(this.props.order, prevProps.order)) {
      this.props.saveOrderIfNeeded();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.elementAnimation);
    clearTimeout(this.layoutReadyTimer);
  }
  getPurchaseValueForAnalytics = (eventType) => {
    const {
      checkoutPriceDetails,
      priceDetails: paymentPriceDetails,
      order: { orderPlaced },
    } = this.props;

    const priceDetails = orderPlaced
      ? paymentPriceDetails
      : checkoutPriceDetails;

    console.log(
      "Sending facebook pixel for",
      priceDetails.priceOnReceipt
        ? priceDetails.priceOnReceipt
        : priceDetails.total,
    );
    switch (eventType) {
      case "totalAfterDiscount":
        return priceDetails.priceOnReceipt
          ? priceDetails.priceOnReceipt
          : priceDetails.total;
      case "subtotalBeforeDiscount":
        return priceDetails.subtotal;

      default:
        return priceDetails.priceOnReceipt
          ? priceDetails.priceOnReceipt
          : priceDetails.total;
    }
  };

  fireFBPixelCheckoutEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings, currency },
      },
      order: { orderItems },
    } = this.props;

    const fbInitiateCheckout = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "InitiateCheckout" },
    );
    if (fbInitiateCheckout) {
      const content_ids = _.map(
        orderItems,
        "configuredMenuItemOrderData.menuItemId",
      );

      ReactPixel.track("InitiateCheckout", {
        value: this.getPurchaseValueForAnalytics(
          fbInitiateCheckout,
          "params.amountType",
        ),
        currency,
        content_ids,
        num_items: content_ids.length,
        content: _.map(
          orderItems,
          ({
            configuredMenuItemOrderData: { menuItemId: id, count: quantity },
          }) => ({
            id,
            quantity,
          }),
        ),
      });
    }
  };

  fireFBPixelPaymentEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings, currency },
      },
      order: { orderItems },
    } = this.props;

    const fbAddPaymentInfo = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "AddPaymentInfo" },
    );

    if (fbAddPaymentInfo) {
      const content_ids = _.map(
        orderItems,
        "configuredMenuItemOrderData.menuItemId",
      );

      ReactPixel.track("AddPaymentInfo", {
        value: this.getPurchaseValueForAnalytics(
          fbAddPaymentInfo,
          "params.amountType",
        ),
        currency,
        content_ids,
        content: _.map(
          orderItems,
          ({
            configuredMenuItemOrderData: { menuItemId: id, count: quantity },
          }) => ({
            id,
            quantity,
          }),
        ),
      });
    }
  };

  fireFBPixelPurchaseEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings, currency },
      },
      order: {
        lastOrderDetails: { orderItems },
      },
    } = this.props;

    const fbPurchase = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "Purchase" },
    );

    if (fbPurchase) {
      const content_ids = _.map(
        orderItems,
        "configuredMenuItemOrderData.menuItemId",
      );

      ReactPixel.track("Purchase", {
        value: this.getPurchaseValueForAnalytics(
          fbPurchase,
          "params.amountType",
        ),
        currency,
        content_ids,
        content_type: "product_group",
        content: _.map(
          orderItems,
          ({
            configuredMenuItemOrderData: { menuItemId: id, count: quantity },
          }) => ({
            id,
            quantity,
          }),
        ),
      });
    }
  };

  fireGTMCheckoutPageEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings },
      },
    } = this.props;

    const gtmTrackingIds = _.compact(
      _.map(
        _.filter(_.flatMap(_.filter(trackings, { type: "gtm" }), "events"), {
          type: "CheckoutPage",
        }),
        "params.id",
      ),
    );

    _.forEach(gtmTrackingIds, fireConversionEvent);
  };

  fireGAPaymentConfirmationEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings },
      },
      order: { lastOrderDetails },
      priceDetails,
      currentBranch,
    } = this.props;

    const gaPurchase = _.find(
      _.flatMap(_.filter(trackings, { type: "ga" }), "events"),
      { type: "Purchase" },
    );

    if (gaPurchase) {
      ReactGA.plugin.require("ecommerce");
      const transactionId = _.get(
        lastOrderDetails,
        "paymentResult.transactionId",
      );

      ReactGA.plugin.execute("ecommerce", "addTransaction", {
        id: transactionId,
        affiliation: _.get(currentBranch, "name"),
        revenue: this.getPurchaseValueForAnalytics(
          gaPurchase,
          "params.amountType",
        ),
        tax: _.get(priceDetails, "tax"),
      });

      const orderItems = _.get(
        lastOrderDetails,
        "checkoutResponse.order.orderItems",
      );
      _.forEach(orderItems, (item) => {
        const name =
          _.get(item, "sourceItem.name") ||
          _.get(item, "configuredMenuItemOrderData.itemName");
        const sku =
          _.get(item, "sourceItem.id") ||
          _.get(item, "configuredMenuItemOrderData.menuItemId");
        const price = `${_.get(item, "price")}`;
        const quantity = `${_.get(item, "count")}`;
        if (name && sku) {
          const addItemEventData = {
            id: transactionId,
            name,
            sku,
            price,
            quantity,
          };
          console.log("sending add item event", addItemEventData);
          ReactGA.plugin.execute("ecommerce", "addItem", addItemEventData);
        }
      });

      ReactGA.plugin.execute("ecommerce", "send");
      ReactGA.plugin.execute("ecommerce", "clear");
    }
  };

  fireGTMPaymentConfirmationEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings, currency },
        branches,
      },
      order: { lastOrderDetails },
      priceDetails,
    } = this.props;
    const thisOrdersBranchId = _.get(
      lastOrderDetails,
      "checkoutResponse.order.branchId",
    );

    const gtmTrackingIds = _.compact(
      _.map(
        _.filter(
          _.filter(_.flatMap(_.filter(trackings, { type: "gtm" }), "events"), {
            type: "ConfirmationPage",
          }),
          (event) =>
            _.isEmpty(_.get(event, "params.branchIds")) ||
            _.indexOf(_.get(event, "params.branchIds"), thisOrdersBranchId) >
              -1,
        ),
        "params.id",
      ),
    );

    _.forEach(gtmTrackingIds, fireConversionEvent);

    const branch = _.find(branches, { id: thisOrdersBranchId });
    const purchaseEventParams = {
      affiliation: _.get(branch, "name"),
      transactionId: _.get(lastOrderDetails, "paymentResult.transactionId"),
      orderId: _.get(lastOrderDetails, "id"),
      value: priceDetails.total,
      tax: priceDetails.tax,
      shipping: priceDetails.deliveryPrice || 0,
      currency,
      items: _.map(
        _.get(lastOrderDetails, "checkoutResponse.order.orderItems"),
        ({ configuredMenuItemOrderData, price, count }) => ({
          item_id: configuredMenuItemOrderData.menuItemId,
          item_name: configuredMenuItemOrderData.itemName,
          price,
          quantity: count,
        }),
      ),
    };

    firePurchaseEvent(purchaseEventParams);
  };

  onInputError = (fieldName) => (error) =>
    this.setState({
      [`${fieldName}_ERROR`]: error,
    });

  onInputValid = (fieldName) => (value) => {
    this.setState({
      [`${fieldName}_ERROR`]: null,
      [fieldName]: value,
    });
  };

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = {
      ...this.fieldsRefs,
      [fieldName]: ref,
    };
  };

  isValidField = (field) => !this.state[errorkey(field)];

  isValidFixedRemarks = () => {
    const {
      order,
      pageContext: {
        business: { orderFixedRemarks },
      },
    } = this.props;

    const isValidFixedRemarks = _.every(
      orderFixedRemarks,
      (orderFixedRemark) => {
        const chosenFixedRemark = _.find(order.fixedRemarks, {
          fixedRemarkId: orderFixedRemark.id,
        });
        if (!chosenFixedRemark || _.isEmpty(chosenFixedRemark.selectedValues)) {
          return false;
        }
        if (
          !_.every(chosenFixedRemark.selectedValues, (selectedValue) =>
            _.includes(orderFixedRemark.remarkValues, selectedValue),
          )
        ) {
          return false;
        }
        return true;
      },
    );
    return isValidFixedRemarks;
  };

  isValidServingOptionMandatoryTextFields = () => {
    const { selectedServingOption, order } = this.props;

    const isValid = _.every(
      selectedServingOption.mandatoryTextFields,
      (field) =>
        !_.isEmpty(
          _.get(
            _.find(
              order.mandatoryTextFields,
              ({ field: textField }) => field === textField,
            ),
            "value",
          ),
        ),
    );
    return isValid;
  };

  preTokenizeOrRecacheIfNeeded = (customerDetails) => {
    if (
      _.includes(
        [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
        this.props.paymentTypeDetails.paymentType,
      ) &&
      this.isCurrentPaymentMethodRequireCVV()
    ) {
      if (this.state[PAYMENT.PAYMENT_METHOD_INPUT].spreedlyRecache) {
        return recache();
      }
      return spreedlyTokenize({
        fullName: customerDetails.fullName,
        ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
          "month",
          "year",
          "zipCode",
        ]),
      });
    }
    if (
      this.props.paymentTypeDetails.paymentType === PAYMENT_ID.CARD_CONNECT &&
      document.getElementById("cardConnectToken")
    ) {
      const token = _.get(
        this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
        "token",
      );
      this.setState({
        [PAYMENT.PAYMENT_METHOD_INPUT]: {
          creditCard: {
            ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
              "zipCode",
              "month",
              "year",
            ]),
          },
        },
      });

      return Promise.resolve({
        token,
        paymentMethod: {
          ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
            "month",
            "year",
          ]),
          last_four_digits: token.substr(-4),
        },
      });
    }
    return Promise.resolve();
  };

  onConnectGiftCard = (addExternalGiftCardToAccount) => {
    const fields = [GIFT_CARD_FIELDS.GIFT_CARD_NUMBER];
    Promise.all(
      _.map(
        fields,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
          ),
      ),
    ).then(() => {
      if (this.isValidField(GIFT_CARD_FIELDS.GIFT_CARD_NUMBER)) {
        //add the gift card to the customer
        addExternalGiftCardToAccount({
          cardNumber: this.state[GIFT_CARD_FIELDS.GIFT_CARD_NUMBER],
          cardPIN: this.state[GIFT_CARD_FIELDS.GIFT_CARD_PIN],
        });
      }
    });
  };

  onSubmit = () => {
    if (this.shouldLoadPayment && !this.paymentLoaded) {
      console.error("not handled, zooz should be loaded but it failed to load");
      return;
    }

    const {
      user,
      order,
      pageContext: {
        businessAppConfiguration: { idRequired, showModalToConfirmChosenStore },
        business: { orderFixedRemarks },
      },
      hidePayment,
      hideCash,
      servingOptionDetails,
      checkoutPriceDetails,
      paymentTypeDetails,
    } = this.props;

    const { servingOption = { type: "pickup" } } = servingOptionDetails;
    const hasFreeOrder = hidePayment;
    const shouldPayWithCreditCard =
      !hidePayment && this.state.paymentTypeChosen === CREDIT_CARD;
    const shouldPayWithCash =
      !hidePayment && !hideCash && this.state.paymentTypeChosen === CASH;

    const fields = _.concat(
      servingOption.needsAddress ? FIELDS : MANDATORY_FIELDS,
      shouldPayWithCreditCard ? PAYMENT.PAYMENT_METHOD_INPUT : [],
    );
    const externalGiftCardPayments = _.map(
      order.giftCardsToRedeem,
      (giftCard) => {
        return {
          giftCardNumber: giftCard.cardNumber,
          chargeAmount: {
            amount: giftCard.redeemAmount,
          },
        };
      },
    );

    Promise.all(
      _.map(fields, (field) => {
        return new Promise(
          (resolve) =>
            this.fieldsRefs[field] &&
            this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
        );
      }),
    ).then(() => {
      if (!_.every(MANDATORY_FIELDS, this.isValidField)) {
        this.setState({ errorComponent: CONTACT_DETAILS }, () => {
          this.scrollToErrorComponent();
        });
        console.log("Invalid Form - contact details");
        return;
      }
      if (
        shouldPayWithCreditCard &&
        !this.isValidField(PAYMENT.PAYMENT_METHOD_INPUT)
      ) {
        this.setState({ errorComponent: PAYMENT_DETAILS }, () => {
          this.scrollToErrorComponent();
        });
        console.log("Invalid Form - payment details");
        return;
      }
      if (!_.isEmpty(orderFixedRemarks) && !this.isValidFixedRemarks()) {
        this.setState({ errorComponent: FIXED_REMARKS }, () => {
          this.scrollToErrorComponent();
        });
        console.log("Invalid Form - fixed remarks");
        return;
      }

      if (
        !_.isEmpty(servingOption.mandatoryTextFields) &&
        !this.isValidServingOptionMandatoryTextFields()
      ) {
        this.setState({ errorComponent: FIXED_REMARKS }, () => {
          this.scrollToErrorComponent();
        });
        console.log("Invalid Form - serving option mandatory fields");
        return;
      }

      console.log("Valid Form");
      if (
        showModalToConfirmChosenStore &&
        _.includes(
          [SERVING_OPTION.PICKUP, SERVING_OPTION.SITDOWN],
          servingOption.type,
        )
      ) {
        if (this.state.showConfirmServingOption) {
          this.setState({
            showConfirmServingOption: false,
          });
        } else {
          this.setState({
            showConfirmServingOption: true,
          });
          return;
        }
      }
      try {
        this.fireFBPixelPaymentEventsIfNeeded();
      } catch (e) {
        console.warn("failed to fire fb AddPayment event", e);
      }

      const customerDetails = {
        email: this.state[PAYMENT.EMAIL],
        phoneNumber: this.state[PAYMENT.PHONE_NUMBER],
        fullName: this.state[PAYMENT.NAME],
      };
      const {
        tipAmount,
        chosenTipPercentage,
      } = this.props.checkoutPriceDetails;

      if (servingOption.needsAddress) {
        const deliveryExtraDetails = {
          floor: this.state.FLOOR,
          apartment: this.state.APARTMENT,
          entrance: this.state.ENTRANCE,
          comments: this.state.DELIVERY_COMMENT,
          ...(this.state.paymentTypeChosen !== CASH && {
            tipAmount,
            chosenTipPercentage,
          }),
        };

        console.log({ deliveryExtraDetails });

        this.props.setDeliveryAddress(deliveryExtraDetails, {
          preventAutoUpdate: true,
        });
      } else {
        if (servingOption.offersTip && this.state.paymentTypeChosen !== CASH) {
          const orderTip = { tipAmount, chosenTipPercentage };
          this.props.setOrderTip(orderTip);
        }
      }

      if (hasFreeOrder) {
        this.props.approveFreeOrder({
          customerDetails,
          ...(checkoutPriceDetails.chargeCardDiscount && {
            occiChargeAmount: checkoutPriceDetails.chargeCardDiscount,
          }),
          externalGiftCardPayments,
        });
      } else if (shouldPayWithCreditCard) {
        this.preTokenizeOrRecacheIfNeeded(customerDetails)
          .then((tokenResponse) => {
            const amount = this.props.checkoutPriceDetails.total;

            this.props.payWithCreditCard(amount, {
              customerDetails,
              paymentTypeDetails,
              ...(checkoutPriceDetails.chargeCardDiscount && {
                occiChargeAmount: checkoutPriceDetails.chargeCardDiscount,
              }),
              ...this.state[PAYMENT.PAYMENT_METHOD_INPUT],
              tokenResponse,
              externalGiftCardPayments,
            });
          })
          .catch((error) => {
            console.log(error);
            if (
              _.get(_.head(_.get(error, "recacheError")), "key") ===
              "messages.unable_to_recache_since_storage_state_is_not_retained"
            ) {
              console.log(
                "Removing Payment method because of error :  card was not retained",
              );
              this.removePaymentMethod("Payment method not retained");
            }
          });
      } else if (shouldPayWithCash) {
        this.props.approveCashPayment({
          customerDetails,
          ...(checkoutPriceDetails.chargeCardDiscount && {
            occiChargeAmount: checkoutPriceDetails.chargeCardDiscount.toFixed(
              2,
            ),
          }),
          cashAmount: checkoutPriceDetails.total.toFixed(2),
          externalGiftCardPayments,
        });
      } else {
        this.setState({ errorComponent: PAYMENT_DETAILS }, () => {
          this.scrollToErrorComponent();
        });
        console.error("not handled payment option");
      }
    });
  };

  removePaymentMethod = (reason) => {
    const { user, paymentTypeDetails } = this.props;

    if (!_.isEmpty(user.paymentMethods.data)) {
      const { token } = user.paymentMethods.data[0];
      console.log("Removing current payment method:", token);

      this.props.removePaymentMethod(token, {
        paymentTypeIdentifier: _.get(paymentTypeDetails, "paymentType"),
        reason,
      });
    }
  };

  startNewOrder = () => {
    navigateTo("/");
    this.props.startNewOrder();
  };

  showOrderSummary = () => {
    this.setState({
      showOrderSummary: !this.state.showOrderSummary,
    });
  };

  toggleCreditCardChosen = () =>
    this.setState((state) => ({
      paymentTypeChosen: CREDIT_CARD,
    }));

  toggleCashChosen = () =>
    this.setState((state) => ({
      paymentTypeChosen: CASH,
    }));

  scrollToErrorComponent = () => {
    scroll.top(
      page,
      _.get(this.formRefs, `[${this.state.errorComponent}].offsetTop`) - 150,
      () => {
        this.setState({ animateErrorElement: true }, () => {
          clearTimeout(this.elementAnimation);
          this.elementAnimation = setTimeout(
            () =>
              this.setState({
                animateErrorElement: false,
              }),
            300,
          );
        });
      },
    );
  };

  isCurrentPaymentMethodRequireCVV = () => {
    const { user } = this.props;
    if (!user.loggedIn) {
      return true;
    }
    const currentPaymentMethod = _.get(user, "paymentMethods.data[0]");
    if (!currentPaymentMethod) {
      return true;
    }

    // in case of a null this is was not defined therefore we will require cvv for any case.
    return currentPaymentMethod.cvvMandatory !== false;
  };

  render() {
    const {
      appStyles,
      pageContext: {
        business: {
          orderFixedRemarks,
          currencySymbol,
          name,

          areaCode,
          mapsCredentialsKey,
        },
        businessAppConfiguration: {
          idRequired,
          offersDeliveryTip,
          showStoreInPlaceOrderButton,
          enableValutecGiftCards,
          enableFeedback,
        },
      },
      location,
      user,
      order,
      checkedoutOrderItemsForPayment,
      servingOptionDetails,
      priceDetails,
      checkoutPriceDetails,
      discountItems,
      servingTime,
      hidePayment,
      hideCash,
      isSavingOrder,
      T,
      app: { keyboardOpen, isSSR },
      pickupTimes,
      orderValidationErrors: { generalErrors },
      currentBranch,
      allowCashPayment,
      allowCreditCardPayment,
      setFixedRemark,
      selectedServingOption,
      paymentTypeDetails,
      setServingOptionMandatoryField,
      setGiftCardsToRedeem,
      openAuthModal,
      addExternalGiftCardToAccount,
    } = this.props;

    const { PageHeader = {}, TitleOnBackground = {} } = appStyles;
    this.params = getParams(location);
    const defaultItemImage = getAppMedia(
      mediaTypes.defaultItemImage,
      appStyles,
    );
    const NoImageRenderer = defaultItemImage
      ? () => (
          <Image
            mediaType={mediaTypes.defaultItemImage}
            mediaKey={defaultItemImage.imageKey}
            imagePreview={defaultItemImage.imagePreview}
            sizes="90px"
            wrapperStyle={{ width: 90, height: 90 }}
            imgStyle={{ objectFit: "contain" }}
          />
        )
      : PlateIcon;

    const { requireZipCode } = paymentTypeDetails || {};
    const requireCVV = this.isCurrentPaymentMethodRequireCVV();

    const {
      servingOption = {
        type: SERVING_OPTION.PICKUP,
      },
      branch = { name: "", address: "" },
    } = servingOptionDetails;

    const shouldShowWarningMessage =
      !isSSR && !pickupTimes.loading && pickupTimes.status !== "AVAILABLE_NOW";
    const isError = Boolean(
      order.orderItems.length &&
        (shouldShowWarningMessage || generalErrors.length > 0) &&
        !isSavingOrder,
    );

    const getInputPropsFor = (inputId, refKey = "refEl") => ({
      appStyles,
      [refKey]: this.registerInput(inputId),
      onValid: this.onInputValid(inputId),
      onError: this.onInputError(inputId),
      rtl: this.props.appStyles.rtl,
      T: this.props.T,
    });

    const pickupTime =
      (order.pickupTime === NOW
        ? _.get(
            getPickupTimesTranslatedInSelectForm({
              pickupTimes,
              T,
            }),
            "[0].label",
          )
        : order.pickupTime) || `${T("Now (Up to")} ${servingTime} ${T("min)")}`;

    const servingOptionOffersTip =
      this.state.paymentTypeChosen !== CASH &&
      servingOption.offersTip &&
      !_.includes(
        _.get(currentBranch, "tipDisabledServingOptionTypes"),
        servingOption.type,
      );

    const offerDeliveryTip =
      this.state.paymentTypeChosen !== CASH &&
      servingOption.needsAddress &&
      offersDeliveryTip &&
      !_.get(currentBranch, "disableDeliveryTip");

    const enableCustomTip = _.get(this.props.currentBranch, "enableCustomTip");

    const tipOptions = _.compact([
      { label: T("No Tip"), value: 0 },
      ..._.map(
        _.get(
          currentBranch,
          `servingOptionTypeToTipOptions.${servingOption.type}`,
          DEFAULT_TIP_OPTIONS,
        ),
        (option) => ({
          label: `${option}%`,
          value: option / 100,
        }),
      ),
      enableCustomTip && {
        value: CUSTOM_TIP_OPTION_VALUE,
        label: T(SET_TIP_LABEL),
      },
    ]);

    if (order.orderPlaced) {
      const servingTime = order.futureServingTime
        ? `${moment
            .tz(moment(order.futureServingTime), currentBranch.timeZoneStr)
            .format("MMM D, YYYY")} ${pickupTime}`
        : pickupTime;

      return (
        <AppContainer.Content
          classNames={styles.PaymentSuccessContent}
          tightBottom
          appStyles={appStyles}
        >
          <AppContainer.CenteredColumn style={{ marginTop: 22 }}>
            <h2
              style={{
                ...PageHeader,
                ...TitleOnBackground,
              }}
            >
              {T("Thanks")}{" "}
              {this.state[PAYMENT.NAME] ||
                (user.loggedIn && user.userDetails.data.name)}
              !
            </h2>
            <p
              style={{
                width: "80%",
                marginTop: 0,
                fontSize: "0.8125rem",
              }}
            >
              {T(
                "Your order was placed successfully. A confirmation email was sent to",
              )}{" "}
              {this.state[PAYMENT.EMAIL] ||
                (user.loggedIn && user.userDetails.data.email)}
            </p>
          </AppContainer.CenteredColumn>
          <Card appStyles={appStyles}>
            <List tight className={styles.CheckoutSummary}>
              {_.get(order, "lastOrderDetails.paymentResult.transactionId") && (
                <List.Item>
                  <span className={styles.CheckoutSummaryLabel}>
                    {T("Order Num.")}
                  </span>
                  <span style={{ textAlign: "end" }}>
                    {_.get(
                      order,
                      "lastOrderDetails.paymentResult.transactionId",
                    )}
                  </span>
                </List.Item>
              )}

              <List.Item>
                <span className={styles.CheckoutSummaryLabel}>
                  {T(
                    `${selectedServingOption.servingOptionTag} ${T(
                      "Location",
                    )}`,
                  )}
                </span>
                <span style={{ textAlign: "end" }}>
                  {selectedServingOption.needsAddress
                    ? _.values(
                        _.pick(
                          _.get(
                            order,
                            "lastOrderDetails.checkoutResponse.order.deliveryAddress",
                          ),
                          ["city", "street", "number"],
                        ),
                      ).join(", ")
                    : branch.address}
                </span>
              </List.Item>
              {!selectedServingOption.needsAddress &&
                appStyles.navigateWithWaze && (
                  <List.Item centered>
                    <WazeButton
                      linkTitle={branch.address}
                      geoPoint={branch.geoPoint}
                      time={new Date().getTime()}
                      T={T}
                    />
                  </List.Item>
                )}
              <List.Item>
                <span className={styles.CheckoutSummaryLabel}>
                  {T(`${selectedServingOption.servingOptionTag} ${T("Time")}`)}
                </span>
                <span style={{ textAlign: "end" }}>{servingTime}</span>
              </List.Item>

              <List.Item>
                <span className={styles.CheckoutSummaryLabel}>
                  {T("Store Phone Num.")}
                </span>
                <a
                  href={`tel:${branch.phoneNumber &&
                    branch.phoneNumber.replace(/\D/g, "")}`}
                  style={{
                    textAlign: "end",
                    textDecoration: "underline",
                    color: "currentColor",
                  }}
                >
                  {branch.phoneNumber}
                </a>
              </List.Item>
              <List.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <span>{T("Order Summary")}</span>
                  <div
                    style={{
                      position: "absolute",
                      fontWeight: "bold",
                      color: appStyles.actionColor,
                      ...(appStyles.rtl ? { left: 0 } : { right: 0 }),
                    }}
                    appStyles={appStyles}
                    linkStyle
                    onClick={this.showOrderSummary}
                  >
                    <strong
                      style={
                        appStyles.rtl ? { marginLeft: 5 } : { marginRight: 5 }
                      }
                    >
                      {this.state.showOrderSummary
                        ? T("Hide Details")
                        : T("Show Details")}
                    </strong>
                    <ChevronDownIcon
                      className={classnames(
                        this.state.showOrderSummary && styles.OrderSummary,
                      )}
                    />
                  </div>
                </div>

                {this.state.showOrderSummary && (
                  <List>
                    {_.map(
                      checkedoutOrderItemsForPayment || [],
                      (item, itemIndex) => (
                        <OrderItem
                          appStyles={appStyles}
                          item={item}
                          key={itemIndex}
                          T={T}
                          currencySymbol={currencySymbol}
                          noImageRenderer={NoImageRenderer}
                        />
                      ),
                    )}
                    {_.map(discountItems || [], (item, index) => (
                      <List.Item
                        noBorder={discountItems.length - 1 === index}
                        tight
                        key={index}
                      >
                        <List.Item.Image
                          className={styles.ItemImage}
                          style={
                            appStyles.rtl
                              ? {
                                  marginLeft: 10,
                                }
                              : {
                                  marginRight: 10,
                                }
                          }
                          width={90}
                          height={64}
                          // {...itemImages[
                          //   _.head(item.relevantOrderMenuItemIds)
                          // ] &&
                          //   itemImages[_.head(item.relevantOrderMenuItemIds)]
                          //     .node}
                          background="#eee"
                          noImageRenderer={PlateIcon}
                          borderRadius={appStyles.cardBorderRadius}
                        />
                        <List.Item.Content>
                          <List.Item.Title light>
                            <strong>{item.description}</strong>

                            <strong
                              style={{
                                color: appStyles.accentColor,
                              }}
                            >
                              (-
                              {currencySymbol}
                              {(isNaN(item.discountAmount) ||
                              item.discountAmount === null
                                ? 0
                                : item.discountAmount
                              ).toFixed(2)}
                              )
                            </strong>
                          </List.Item.Title>
                        </List.Item.Content>
                      </List.Item>
                    ))}
                    <TotalPrice
                      appStyles={appStyles}
                      priceDetails={priceDetails}
                      currencySymbol={currencySymbol}
                      T={T}
                      businessName={name}
                    />
                  </List>
                )}
              </List.Item>
              <List.Item>
                {this.state.balanceChange > 0 && (
                  <span>
                    {T("You have earned ")}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.balanceChange} {T("points")}
                    </span>
                    {T(" in this order!")}
                    {T("{pointsRedemptionDescription}") !==
                      "{pointsRedemptionDescription}" && (
                      <div className={styles.MemberSince}>
                        {T("{pointsRedemptionDescription}")}
                      </div>
                    )}
                  </span>
                )}
              </List.Item>
              {enableFeedback && appStyles.showContactUsOnThankYou && (
                <>
                  <List.Separator />
                  <ExternalLink
                    link={_.get(appStyles.contactUsAlternative, "href")}
                    fallbackLink="/contact-us"
                    appStyles={appStyles}
                  >
                    <List.Item
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        ...appStyles.LinkButton,
                      }}
                    >
                      {T(
                        _.get(
                          appStyles,
                          "contactUsAlternative.label",
                          LABELS.CONTACT_US,
                        ),
                      )}
                    </List.Item>
                  </ExternalLink>
                </>
              )}
            </List>
            <Button
              onClick={this.startNewOrder}
              appStyles={appStyles}
              centered
              marginTop
              classNames={styles.PaymentFooterDesktop}
            >
              <span>{T("Back to home screen")}</span>
            </Button>
          </Card>
          <AppContainer.Footer
            relativePosition={keyboardOpen}
            ref={(el) => {
              this.footer = el;
            }}
            appStyles={appStyles}
            transparentGradient
            center
            classNames={styles.PaymentFooterMobile}
          >
            <AppContainer.Footer.Button
              onClick={this.startNewOrder}
              appStyles={appStyles}
              center
              spread
            >
              <span>{T("Back to home screen")}</span>
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        </AppContainer.Content>
      );
    } else {
      const showPickupLocation =
        showStoreInPlaceOrderButton &&
        _.includes(
          [SERVING_OPTION.PICKUP, SERVING_OPTION.SITDOWN],
          servingOption.type,
        );

      const isCashOnly =
        !hidePayment && !allowCreditCardPayment && allowCashPayment;

      const payButtonText = `${T("Place Order")} ${
        isCashOnly ? T("(Cash Only)") : ""
      }`;

      const payButtonContent = showPickupLocation ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>{payButtonText}</div>
          <div>
            <small>
              {T(servingOption.servingOptionTag)} {T("from")}{" "}
            </small>
            <strong>{branch.name}</strong>
          </div>
        </div>
      ) : (
        <span>{payButtonText}</span>
      );

      const servingTime = order.futureServingTime
        ? `${moment
            .tz(moment(order.futureServingTime), currentBranch.timeZoneStr)
            .format("MMM D, YYYY")} ${T("at")} ${pickupTime}`
        : pickupTime;

      const servingOptionMapAlternative = _.get(
        appStyles.servingOptionMapAlternative,
        servingOption.type,
      );

      return order.placingOrder ? (
        <AppContainer.Content
          classNames={styles.AppContainer}
          tightBottom
          style={{
            minHeight: "calc(100vh - 60px)",
          }}
          appStyles={appStyles}
        >
          {order.shouldRequestUserToCheckOrderStatus ? (
            <AppContainer.CenteredColumn style={{ marginTop: 40 }}>
              <BagIcon
                className={styles.BagIcon}
                style={{
                  color: appStyles.actionColor,
                }}
              />
              <strong
                style={{
                  marginBottom: 10,
                  padding: 32,
                }}
              >
                {T("Sending your order is taking longer than usual")}
              </strong>

              <Button
                appStyles={appStyles}
                onClick={this.props.checkOrderStatus}
              >
                {T("Check order status now")}
              </Button>
            </AppContainer.CenteredColumn>
          ) : (
            <AppContainer.CenteredColumn>
              <Loader appStyles={appStyles} classNames={styles.Loader} />
              <strong>{T("Placing your order...")}</strong>
              {order.shouldCheckOrderStatus && (
                <strong>
                  {T(
                    "This is taking longer than usual, checking order status...",
                  )}
                </strong>
              )}
            </AppContainer.CenteredColumn>
          )}
          {order.shouldRequestUserToCheckOrderStatus && (
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              ref={(el) => {
                this.footer = el;
              }}
              appStyles={appStyles}
              transparentGradient
              center
              classNames={styles.PaymentFooterMobile}
            >
              <AppContainer.Footer.Button
                onClick={this.startNewOrder}
                appStyles={appStyles}
                center
                spread
              >
                <span>{T("Back to home screen")}</span>
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          )}
        </AppContainer.Content>
      ) : (
        <AppContainer.ResponsiveWrapper classNames={styles.ResponsiveWrapper}>
          <ConfirmServingOption
            T={T}
            appStyles={appStyles}
            servingOption={servingOption}
            branch={branch}
            show={this.state.showConfirmServingOption}
            onConfirm={this.onSubmit}
            onClose={() =>
              this.setState({
                showConfirmServingOption: false,
              })
            }
          />

          <AppContainer.Content
            classNames={classnames(
              styles.Content,
              isError && styles.ErrorContent,
              isSavingOrder && styles.SavingOrder,
            )}
            tightBottom
            appStyles={appStyles}
          >
            {order.placeOrderError && (
              <ErrorMessage appStyles={appStyles}>
                {T(`${order.placeOrderError}`)}
              </ErrorMessage>
            )}
            {this.state.shouldLoadPayment && (
              <PaymentAPIScriptLoader
                paymentTypeDetails={paymentTypeDetails}
                onLoad={() =>
                  this.setState({
                    paymentLoaded: true,
                  })
                }
              />
            )}
            <SelectedOptionBox
              title={T(servingOption.servingOptionTag)}
              itemLabel={
                servingOption.needsAddress ? `${T("To:")} ` : `${T("From:")} `
              }
              itemIconComponent={LocationPinIcon}
              itemIconStyles={styles.IconComponent}
              itemValueRenderer={
                servingOption.needsAddress ? (
                  order.deliveryAddress && order.deliveryAddress.description
                ) : (
                  <span
                    style={{
                      flex: "1 0 auto",
                      fontSize: "1.2rem",
                    }}
                  >
                    <span>{branch.name} | </span>
                    <small>{branch.address}</small>
                  </span>
                )
              }
              appStyles={appStyles}
            >
              {order.tableCode && (
                <div style={{ marginBottom: 6 }}>
                  <TableIcon className={styles.IconComponent} />
                  {T("Table Chosen:")}{" "}
                  <span style={{ fontWeight: "bold" }}>{order.tableCode}</span>
                </div>
              )}
              {servingOption.needsAddress && (
                <div>
                  <div style={{ paddingTop: 10 }}>{T("Comments")}</div>
                  <TextInput
                    {...getInputPropsFor(DELIVERY_COMMENT)}
                    noCheckmark
                    customInputAttributes={{ maxLength: 279 }}
                    initialValue={
                      (order.deliveryAddress &&
                        order.deliveryAddress.comments) ||
                      ""
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: 90,
                      }}
                    >
                      <small>{T("Apartment")}</small>
                      <TextInput
                        {...getInputPropsFor(APARTMENT)}
                        noCheckmark
                        initialValue={
                          (order.deliveryAddress &&
                            order.deliveryAddress.apartment) ||
                          ""
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        ...(appStyles.rtl
                          ? { marginRight: "1em" }
                          : {
                              marginLeft: "1em",
                            }),
                        width: 50,
                      }}
                    >
                      <small>{T("Floor")}</small>
                      <TextInput
                        {...getInputPropsFor(FLOOR)}
                        noCheckmark
                        initialValue={
                          (order.deliveryAddress &&
                            order.deliveryAddress.floor) ||
                          ""
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        ...(appStyles.rtl
                          ? { marginRight: "1em" }
                          : {
                              marginLeft: "1em",
                            }),
                        width: 80,
                      }}
                    >
                      <small>{T("Entrance")}</small>
                      <TextInput
                        {...getInputPropsFor(ENTRANCE)}
                        noCheckmark
                        initialValue={
                          (order.deliveryAddress &&
                            order.deliveryAddress.entrance) ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {servingOptionMapAlternative ? (
                <React.Fragment>
                  <h3>{_.get(servingOptionMapAlternative, "message", "")}</h3>
                  <p>
                    {_.get(servingOptionMapAlternative, "smallMessage", "")}
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {!isSSR && (
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/place?key=${
                        mapsCredentialsKey
                          ? mapsCredentialsKey
                          : "AIzaSyDMFj-OseCoZgiq2mYCFYefmW5Uo-gCJ9A"
                      }&q=${_.get(branch, "address")}`}
                      frameBorder="0"
                      style={{
                        border: 0,
                        width: "100%",
                      }}
                      allowFullscreen
                    />
                  )}
                  <div style={{ marginTop: 20 }}>
                    <div>
                      {T(
                        `${selectedServingOption.servingOptionTag} ${T(
                          "Time:",
                        )} `,
                      ) + servingTime}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </SelectedOptionBox>
            <ContactDetails
              T={T}
              appStyles={appStyles}
              hasErrors={this.state.errorComponent === CONTACT_DETAILS}
              animateError={
                this.state.errorComponent === CONTACT_DETAILS &&
                this.state.animateErrorElement
              }
              refEl={(ref) => (this.formRefs[CONTACT_DETAILS] = ref)}
              inputProps={{
                [PAYMENT.NAME]: {
                  ...getInputPropsFor(PAYMENT.NAME),
                  initialValue: user.loggedIn
                    ? user.userDetails.data.name
                    : this.state[PAYMENT.NAME],
                },
                [PAYMENT.PHONE_NUMBER]: {
                  ...getInputPropsFor(PAYMENT.PHONE_NUMBER),
                  value: user.loggedIn && user.userDetails.data.phoneNumber,
                  disabled: user.loggedIn,
                },
                [PAYMENT.EMAIL]: {
                  ...getInputPropsFor(PAYMENT.EMAIL),
                  initialValue: user.loggedIn
                    ? user.userDetails.data.email
                    : this.state[PAYMENT.EMAIL],
                },
              }}
              title={T("Contact Details")}
              areaCode={areaCode}
            />
            {(!_.isEmpty(orderFixedRemarks) ||
              !_.isEmpty(selectedServingOption.mandatoryTextFields)) && (
              <Card
                appStyles={appStyles}
                hasErrors={this.state.errorComponent === FIXED_REMARKS}
                animateError={
                  this.state.errorComponent === FIXED_REMARKS &&
                  this.state.animateErrorElement
                }
                refEl={(ref) => (this.formRefs[FIXED_REMARKS] = ref)}
              >
                <Card.Title light appStyles={appStyles}>
                  {T("Order Details")}
                </Card.Title>
                <Card.Description
                  style={{
                    color: appStyles.accentColor,
                  }}
                >
                  {T("* Mandatory")}
                </Card.Description>
                <InputGroup tight appStyles={appStyles} T={T}>
                  {_.map(orderFixedRemarks || [], (fixedRemark) => {
                    const remarkValueSelected = _.find(
                      order.fixedRemarks,
                      (remark) => remark.fixedRemarkId === fixedRemark.id,
                    );
                    const selectedIndex =
                      remarkValueSelected &&
                      _.findIndex(
                        fixedRemark.remarkValues,
                        (value) =>
                          remarkValueSelected.selectedValues[0] === value,
                      );

                    return (
                      <div>
                        <div>{T(fixedRemark.name)}</div>
                        <InlineSelect
                          rtl={appStyles.rtl}
                          selectedIndex={selectedIndex}
                          options={_.map(
                            fixedRemark.remarkValues || [],
                            (remarkValue) => ({
                              label: remarkValue,
                              value: remarkValue,
                            }),
                          )}
                          onChange={(value) =>
                            setFixedRemark({
                              fixedRemarkId: fixedRemark.id,
                              selectedValues: [value],
                            })
                          }
                          selectedColor={appStyles.actionColor}
                          T={T}
                        />
                      </div>
                    );
                  })}
                </InputGroup>
                <Card.Content>
                  {_.map(selectedServingOption.mandatoryTextFields, (field) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>{T(field)}</label>
                      <TextInput
                        noCheckmark
                        notifyAny
                        appStyles={appStyles}
                        T={T}
                        onChange={(value) =>
                          setServingOptionMandatoryField({
                            field,
                            value,
                          })
                        }
                        validator={(input) => !_.isEmpty(input)}
                        errorMessage={`${T("Please Enter")} ${field}`}
                        initialValue={_.get(
                          _.find(
                            order.mandatoryTextFields,
                            ({ field: textField }) => textField === field,
                          ),
                          "value",
                        )}
                      />
                    </div>
                  ))}
                </Card.Content>
              </Card>
            )}
            {(offerDeliveryTip || servingOptionOffersTip) && (
              <Card appStyles={appStyles}>
                <Card.Title light appStyles={appStyles}>
                  {offerDeliveryTip
                    ? T("Driver Tip")
                    : `${T(`${T(servingOption.servingOptionTag)} Tip`)}`}
                </Card.Title>
                <Card.Content>
                  <small>
                    {offerDeliveryTip
                      ? T("Order price does not include driver's tip")
                      : T("Order price does not include gratuity")}
                  </small>
                </Card.Content>
                <InputGroup tight appStyles={appStyles} T={T}>
                  <InlineSelect
                    rtl={appStyles.rtl}
                    options={tipOptions}
                    value={
                      offerDeliveryTip
                        ? order.deliveryTipPercentage
                        : order.servingOptionTipPercentage
                    }
                    onChange={(value) =>
                      offerDeliveryTip
                        ? this.props.setDeliveryTipPercentage(value)
                        : this.props.setServingOptionTipPercentage(value)
                    }
                    selectedColor={appStyles.actionColor}
                    T={T}
                  />
                  {enableCustomTip &&
                    (offerDeliveryTip
                      ? order.deliveryTipPercentage
                      : order.servingOptionTipPercentage) ===
                      CUSTOM_TIP_OPTION_VALUE && (
                      <React.Fragment>
                        <span>
                          {T("Custom Tip amount:")} ({currencySymbol})
                        </span>
                        <TextInput
                          pattern="/^\d{1,10}(\.\d{1,2})?$/"
                          initialValue={
                            offerDeliveryTip
                              ? this.props.order.deliveryTipCustomValue
                              : this.props.order.servingOptionTipCustomValue
                          }
                          type="number"
                          step="any"
                          disabled={
                            (offerDeliveryTip
                              ? order.deliveryTipPercentage
                              : order.servingOptionTipPercentage) !==
                            CUSTOM_TIP_OPTION_VALUE
                          }
                          appStyles={appStyles}
                          style={{
                            width: "50%",
                            color:
                              (offerDeliveryTip
                                ? order.deliveryTipPercentage
                                : order.servingOptionTipPercentage) ===
                              CUSTOM_TIP_OPTION_VALUE
                                ? undefined
                                : "transparent",
                          }}
                          validator={(value) => value > 0}
                          errorMessage={T(
                            `You selected ${T(
                              SET_TIP_LABEL,
                            )}, but did not enter an amount`,
                          )}
                          onChange={(value) =>
                            offerDeliveryTip
                              ? this.props.setDeliveryTipCustomValue(value)
                              : this.props.setServingOptionTipCustomValue(value)
                          }
                        />
                      </React.Fragment>
                    )}
                </InputGroup>
              </Card>
            )}

            {enableValutecGiftCards &&
              !isSavingOrder &&
              checkoutPriceDetails &&
              !isNaN(checkoutPriceDetails.total) &&
              checkoutPriceDetails.total > 0 && (
                <Card appStyles={appStyles}>
                  <Card.Title
                    light
                    appStyles={appStyles}
                    style={{
                      width: "calc(100% - 20px)",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: 0,
                    }}
                  >
                    Gift Cards
                  </Card.Title>
                  {user.loggedIn && (
                    <Card.Content>
                      <small>
                        <Card.Content.CheckboxGroup
                          name={T("Gift Cards")}
                          limit={undefined}
                          checked={_.map(
                            _.filter(
                              order.giftCardsToRedeem,
                              (giftCard) => _.get(giftCard, "redeemAmount") > 0,
                            ),
                            (giftCard) => _.get(giftCard, "cardNumber"),
                          )}
                          onChange={(chosenOptions) => {
                            const totalNotUsedOrderAmount =
                              checkoutPriceDetails.total -
                              this.props.totalExternalGiftCardRedeemAmount;
                            console.log({ totalNotUsedOrderAmount });
                            const chosenGiftCards = _.map(
                              _.filter(
                                _.get(user, "externalGiftCards.data.giftCards"),
                                (giftCard) => {
                                  return (
                                    _.findIndex(
                                      chosenOptions,
                                      (cardNumber) =>
                                        cardNumber === giftCard.cardNumber,
                                    ) > -1
                                  );
                                },
                              ),
                              (giftCard) => {
                                const stateGiftCard = _.filter(
                                  order.giftCardsToRedeem,
                                  (gcToRedeem) =>
                                    _.get(gcToRedeem, "cardNumber") ===
                                    giftCard.cardNumber,
                                );
                                console.log(
                                  "Redeem amount",
                                  _.isEmpty(stateGiftCard)
                                    ? _.min([
                                        totalNotUsedOrderAmount,
                                        giftCard.balance,
                                      ])
                                    : giftCard.balance,
                                );
                                return {
                                  cardNumber: giftCard.cardNumber,
                                  redeemAmount: _.isEmpty(stateGiftCard)
                                    ? _.min([
                                        totalNotUsedOrderAmount,
                                        giftCard.balance,
                                      ])
                                    : giftCard.balance,
                                  balance: giftCard.balance,
                                };
                              },
                            );
                            console.log("chosenGiftCards", chosenGiftCards);
                            setGiftCardsToRedeem(chosenGiftCards);
                          }}
                          appStyles={appStyles}
                        >
                          {_.map(
                            _.filter(
                              _.get(user, "externalGiftCards.data.giftCards"),
                              (gcToRedeem) => _.get(gcToRedeem, "balance") > 0,
                            ),
                            (giftCard, index) => {
                              const stateGiftCard = _.filter(
                                order.giftCardsToRedeem,
                                (gcToRedeem) =>
                                  _.get(gcToRedeem, "cardNumber") ===
                                  giftCard.cardNumber,
                              );
                              const totalNotUsedOrderAmount =
                                checkoutPriceDetails.total -
                                this.props.totalExternalGiftCardRedeemAmount;
                              return (
                                <Card.Content.CheckboxOption
                                  iconSize={16}
                                  iconInnerSize={16}
                                  pointColor={appStyles.actionColor}
                                  value={giftCard.cardNumber}
                                  key={giftCard.cardNumber}
                                  disabled={
                                    _.isEmpty(stateGiftCard) &&
                                    totalNotUsedOrderAmount == 0
                                  }
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>
                                      {T("Card Number")}:{" "}
                                      {_.get(giftCard, "cardNumber")}
                                    </span>
                                    {order.giftCardsToRedeem &&
                                      !_.isEmpty(stateGiftCard) && (
                                        <span>
                                          {T("Use")} {currencySymbol}{" "}
                                          {order.giftCardsToRedeem &&
                                            _.get(
                                              stateGiftCard[0],
                                              "redeemAmount",
                                            ).toFixed(2)}{" "}
                                          from: {currencySymbol}
                                          {_.get(giftCard, "balance")}
                                        </span>
                                      )}
                                    {(!order.giftCardsToRedeem ||
                                      _.isEmpty(stateGiftCard)) &&
                                      totalNotUsedOrderAmount > 0 && (
                                        <span>
                                          {T("Balance:")} {currencySymbol}
                                          {_.get(giftCard, "balance")}
                                        </span>
                                      )}
                                  </span>
                                </Card.Content.CheckboxOption>
                              );
                            },
                          )}
                        </Card.Content.CheckboxGroup>
                      </small>

                      {((_.get(user, "addExternalGiftCardState") &&
                        _.get(user, "addExternalGiftCardState.sending")) ||
                        (_.get(user, "externalGiftCards") &&
                          _.get(user, "externalGiftCards.sending"))) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                          }}
                        >
                          <Loader appStyles={appStyles} />
                        </div>
                      )}
                      {!(
                        (_.get(user, "addExternalGiftCardState") &&
                          _.get(user, "addExternalGiftCardState.sending")) ||
                        (_.get(user, "externalGiftCards") &&
                          _.get(user, "externalGiftCards.sending"))
                      ) && (
                        <span>
                          <small>
                            {T(
                              "For an existing physical gift card, enter the card number and PIN (leave empty if there is no PIN) to connect the card to your account",
                            )}
                          </small>

                          <small
                            style={{
                              width: "100%",
                              display: "flex",
                              flexFlow: "row wrap",
                              justifyContent: "space-between",
                              alignContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <TextInput
                              onValid={(value) => {
                                // setNewGiftCard({ ...newGiftCard, cardNumber: value })
                              }}
                              placeholder={T("Gift Card Number")}
                              refEl={this.registerInput(
                                GIFT_CARD_FIELDS.GIFT_CARD_NUMBER,
                              )}
                              appStyles={appStyles}
                              iconComponent={GiftCard}
                              validator={(input) => !_.isEmpty(input)}
                              errorMessage={T(
                                "Please enter a valid gift card number",
                              )}
                              style={{
                                //marginRight: "5px",
                                minWidth: "60%",
                              }}
                            />

                            <TextInput
                              onValid={(value) => {
                                // setNewGiftCard({ ...newGiftCard, cardPIN: value })
                              }}
                              refEl={this.registerInput(
                                GIFT_CARD_FIELDS.GIFT_CARD_PIN,
                              )}
                              appStyles={appStyles}
                              iconComponent={LockIcon}
                              placeholder={T("Gift Card PIN")}
                              style={{
                                //marginLeft: "5px",
                                minWidth: "35%",
                              }}
                            />
                          </small>

                          <Button
                            style={{
                              margin: 0,
                            }}
                            appStyles={appStyles}
                            centered
                            disabled
                            onClick={() => {
                              this.onConnectGiftCard(
                                addExternalGiftCardToAccount,
                              );
                            }}
                          >
                            {T("Connect Gift Card")}
                          </Button>
                        </span>
                      )}
                    </Card.Content>
                  )}
                  {!user.loggedIn && (
                    <Card.Content>
                      <small>
                        {T(
                          "For an existing physical gift card, click on the button below to signup and connect the card to your account",
                        )}
                      </small>
                      <Button
                        style={{
                          margin: 0,
                          marginTop: "10px",
                        }}
                        appStyles={appStyles}
                        centered
                        disabled
                        onClick={() => {
                          openAuthModal(LOGIN_TYPES.GIFT_CARD);
                        }}
                      >
                        {T("Signup and connect Gift Card")}
                      </Button>
                    </Card.Content>
                  )}
                </Card>
              )}

            {!hidePayment && allowCreditCardPayment && (
              <Card
                appStyles={appStyles}
                hasErrors={this.state.errorComponent === PAYMENT_DETAILS}
                animateError={
                  this.state.errorComponent === PAYMENT_DETAILS &&
                  this.state.animateErrorElement
                }
                refEl={(ref) => (this.formRefs[PAYMENT_DETAILS] = ref)}
              >
                {allowCashPayment && !hideCash && allowCreditCardPayment && (
                  <Card.Title
                    appStyles={appStyles}
                    style={{
                      width: "calc(100% - 20px)",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: 0,
                    }}
                  >
                    <div
                      style={{
                        flexGrow: 1,
                        ...(appStyles.rtl
                          ? { marginLeft: 20 }
                          : {
                              marginRight: 20,
                            }),
                      }}
                    >
                      <Button
                        onClick={this.toggleCreditCardChosen}
                        appStyles={appStyles}
                        centered
                        classNames={styles.PaymentTypeButton}
                        style={
                          this.state.paymentTypeChosen === CREDIT_CARD
                            ? {
                                backgroundColor: appStyles.actionColor,
                                border: `2px solid ${appStyles.actionColor}`,
                                color: appStyles.cardBackgroundColor,
                              }
                            : {
                                backgroundImage: "none",
                                backgroundColor: "transparent",
                                border: `2px solid ${appStyles.actionColor}`,
                                color: appStyles.actionColor,
                              }
                        }
                      >
                        <span
                          style={
                            appStyles.rtl
                              ? {
                                  marginLeft: 6,
                                }
                              : {
                                  marginRight: 6,
                                }
                          }
                        >
                          {T("Credit Card")}
                        </span>
                        <CreditCardIcon className={styles.CreditCardIcon} />
                      </Button>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <Button
                        onClick={this.toggleCashChosen}
                        appStyles={appStyles}
                        centered
                        classNames={styles.PaymentTypeButton}
                        style={
                          this.state.paymentTypeChosen === CASH
                            ? {
                                backgroundColor: appStyles.actionColor,
                                border: `2px solid ${appStyles.actionColor}`,
                                color: appStyles.cardBackgroundColor,
                              }
                            : {
                                backgroundImage: "none",
                                backgroundColor: "transparent",
                                border: `2px solid ${appStyles.actionColor}`,
                                color: appStyles.actionColor,
                              }
                        }
                      >
                        <span
                          style={
                            appStyles.rtl
                              ? {
                                  marginLeft: 6,
                                }
                              : {
                                  marginRight: 6,
                                }
                          }
                        >
                          {T("Cash")}
                        </span>
                        <CashIcon />
                      </Button>
                    </div>
                  </Card.Title>
                )}

                {this.state.layoutReady &&
                  this.state.paymentTypeChosen === CREDIT_CARD &&
                  (_.get(user, "paymentMethods.loading") ||
                  user.rechargeCard.sending ? (
                    <AppContainer.CenteredColumn style={{ marginTop: 30 }}>
                      <Loader color={appStyles.accentColor} />
                    </AppContainer.CenteredColumn>
                  ) : (
                    <PaymentMethodInput
                      user={user}
                      T={T}
                      appStyles={appStyles}
                      getInputPropsFor={getInputPropsFor}
                      changeTargetURL={setParams("/payment-methods", {
                        ...this.params,
                        backPath: "/payment",
                      })}
                      idRequired={idRequired}
                      requireZipCode={requireZipCode}
                      requireCVV={requireCVV}
                      ref={this.registerInput(PAYMENT.PAYMENT_METHOD_INPUT)}
                      paymentTypeDetails={paymentTypeDetails}
                      paymentScriptLoaded={this.state.paymentLoaded}
                    />
                  ))}
                {this.state.layoutReady &&
                  this.state.paymentTypeChosen === CASH &&
                  !_.isEmpty(T("{{cash_only_notice}}")) && (
                    <Card.Content>{T("{{cash_only_notice}}")}</Card.Content>
                  )}
              </Card>
            )}
            {isCashOnly && !_.isEmpty(T("{{cash_only_notice}}")) && (
              <Card appStyles={appStyles} hasErrors>
                <Card.Content>{T("{{cash_only_notice}}")}</Card.Content>
              </Card>
            )}
            {!_.isEmpty(order.orderItems) && (
              <Button
                onClick={this.onSubmit}
                appStyles={appStyles}
                marginTop
                spread
                style={{ height: 90 }}
                classNames={styles.PaymentFooterDesktop}
              >
                {payButtonContent}
                {!isSavingOrder &&
                  checkoutPriceDetails &&
                  checkoutPriceDetails.total > 0 && (
                    <span>
                      {isNaN(checkoutPriceDetails.total)
                        ? ""
                        : `${currencySymbol}${(
                            checkoutPriceDetails.total -
                            this.props.totalExternalGiftCardRedeemAmount
                          ).toFixed(2)}`}
                    </span>
                  )}
              </Button>
            )}

            <AppContainer.Footer
              relativePosition={keyboardOpen}
              ref={(el) => {
                this.footer = el;
              }}
              appStyles={appStyles}
              transparentGradient
              center
              classNames={styles.PaymentFooterMobile}
            >
              <AppContainer.Footer.Button
                onClick={this.onSubmit}
                appStyles={appStyles}
                spread
                style={{ height: 66 }}
              >
                <span>{payButtonContent}</span>
                {!isSavingOrder &&
                  checkoutPriceDetails &&
                  checkoutPriceDetails.total > 0 && (
                    <span>
                      {isNaN(checkoutPriceDetails.total)
                        ? ""
                        : `${currencySymbol}${(
                            checkoutPriceDetails.total -
                            this.props.totalExternalGiftCardRedeemAmount
                          ).toFixed(2)}`}
                    </span>
                  )}
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          </AppContainer.Content>
          <AppContainer.SecondaryContent
            classNames={classnames(
              styles.SecondaryContent,
              isError && styles.ErrorSecondaryContent,
            )}
          >
            <CheckoutView
              {...this.props}
              paymentViewMode
              priceDetails={checkoutPriceDetails}
            />
          </AppContainer.SecondaryContent>
        </AppContainer.ResponsiveWrapper>
      );
    }
  }
}
